<template>
  <div class="main">
    <el-form ref="form" :model="form" :rules="rules" class="form" label-position="left">
      <div class="registration-information">
        <div class="text">报名信息</div>
        <div class="form">
          <el-form-item v-for="(i,n) in formArr" v-if='n<9' :key="i.label" :label="i.label" :prop="i.value" label-width="140rem">
            <el-input v-if="i.label==='学生姓名'" v-model="form[i.value]" :placeholder="i.placeholder" maxlength="18"></el-input>
            <el-input v-if="i.label==='学生身份证号'" v-model="form[i.value]" :placeholder="i.placeholder" maxlength="18" @input="(v) => (form[i.value] = v.replace(/[^\dX|x]/g,''))"></el-input>
            <el-select v-if="i.label!=='学生姓名'&&i.label!=='学生身份证号'&&i.options" v-model="form[i.value]" :placeholder="i.placeholder" @change="i.change ? i.change($event) : null">
              <el-option v-for="i_ in i.options" :key="i_.value" :label="i.label_?i_[i.label_]:i_" :value="i.value_?i_[i.value_]:i_+''"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="registration-information" style="margin: 24rem 0">
        <div class="text">缴费信息</div>
        <div class="form">
          <el-form-item v-for="(i,n) in formArr" v-if='n>=9&&n<=12&&!i.show' :key="i.label" :label="i.label" :prop="i.value" class="form-item" label-width="140rem">
            <el-select v-if="i.label==='缴费方式'" v-model="form[i.value]" :placeholder="i.placeholder" @change="i.change ? i.change($event) : null">
              <el-option v-for="i_ in i.options" :key="i_.value" :label="i.label_?i_[i.label_]:i_" :value="i.value_?i_[i.value_]:i_"></el-option>
            </el-select>
            <el-select v-if="i.label==='是否缴纳高一学年生活费'" v-model="form[i.value]" :placeholder="i.placeholder">
              <el-option v-for="i_ in i.options" :key="i_.value" :label="i.label_?i_[i.label_]:i_" :value="i.value_?i_[i.value_]:i_"></el-option>
            </el-select>
            <el-input v-if="i.label===dynamicText" v-model="form[i.value]" :placeholder="i.placeholder" :type="'number'" maxlength="18" @input="(v) => (form[i.value] = v.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace(/^(\d+)\.(\d\d).*$/, '$1.$2'))"></el-input>
            <el-date-picker v-if="i.label==='缴费时间'" v-model="form[i.value]" placeholder="选择日期时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <div style="grid-column: 1 / -1">
            <el-form-item v-for="(i,n) in formArr" v-if='n===13' :key="i.label" :label="i.label" :prop="i.value" label-position="top" label-width="140rem">
              <el-checkbox-group v-if="i.label==='所参与优惠政策'" v-model="form[i.value]">
                <el-button v-for="i_ in i.options" style="margin-right: 24rem" :key="i_['policy']" :type="!form.policy.includes(i_.policy) ? '' : 'primary'" @click="onPolicy(i_,n)">{{ i_['policy_name'] }}</el-button>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div style="grid-column: 1 / -1">
            <el-form-item v-for="(i,n) in formArr" v-if='n>=14&&n<=16&&!i.show' :key="i.label" :class="form.policy[0] === 5 ? 'form-item1':''" :label="i.label" :prop="i.value" label-position="top" label-width="140rem">
              <el-input v-if="i.label==='其他优惠原因'" v-model="form[i.value]" :placeholder="i.placeholder" :rows="3" hight="100rem" maxlength="200" show-word-limit type="textarea"></el-input>
              <el-input v-if="i.label==='【特殊减免】优惠原因'" v-model="form[i.value]" :placeholder="i.placeholder" :rows="3" hight="100rem" maxlength="200" show-word-limit type="textarea"></el-input>
              <span v-if="i.label==='【特殊减免】优惠原因'" style="color: #F25858;background: #FFEAEA;margin-top: 4rem;padding: 4rem;font-size: 12rem">
							<i class="el-icon-warning-outline"></i>
								特殊减免优惠将会发起钉钉审批！
							</span>
              <l-upload v-if="i.label==='缴费凭证'" @imgUpload="imgUpload" @imgUploadRemove="imgUploadRemove"></l-upload>
            </el-form-item>
          </div>
        </div>
      </div>

      <div class="registration-information" style="margin: 24rem 0">
        <div class="text">续费信息</div>
        <div class="form">
          <el-form-item v-for="(i,n) in formArr" v-if='n>16' :key="i.label" :label="i.label" label-width="130rem">
            <el-input v-model="form[i.value]" :placeholder="i.placeholder" :type="'number'" maxlength="18" @input="(v) => (form[i.value] = v.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace(/^(\d+)\.(\d\d).*$/, '$1.$2'))"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="registration-information" style="margin: 24rem 0;">
        <div class="text">备注</div>
        <div class="form" style="display: flex !important;">
          <el-input v-model="form.remarks" :rows="3" hight="100rem" maxlength="300" placeholder="请输入" show-word-limit style="width: 100%" type="textarea"></el-input>
        </div>
      </div>
    </el-form>
    <div class="button">
      <el-button type="primary" @click="submitForm">提交</el-button>
    </div>

    <el-dialog :before-close="()=>dialogVisible=false" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%">
      <div style="display: flex;justify-content: center;margin-bottom: 48rem">
				<span v-if="!errMsg" :style="{width: form.payment_type === 1 ? '410rem' : '400rem'}" style="display: inline-block;width: 400rem;font-weight: 500;font-size: 24rem;color: rgba(0,0,0,0.8);text-align: center">
					{{ this.form.payment_type === 1 ? '提交后家长报名时将会以该金额进行支付，请确认家长应缴金额是否无误？' : '提交后家长报名时将无需再次缴费，请确认家长缴费金额是否无误？' }}
				</span>
        <span v-else style="display: inline-block;width: 400rem;font-weight: 500;font-size: 24rem;color: rgba(0,0,0,0.8);text-align: center">
					{{ errMsg }}
				</span>
      </div>
      <div v-if="!errMsg" style="text-align: center">
        <el-button style="margin-right: 24rem;margin-left: -24rem" type="primary" @click="onConfirmed">确认</el-button>
        <el-button plain type="primary" @click="dialogVisible = false">再看看</el-button>
      </div>
      <div v-else style="text-align: center">
        <el-button type="primary" @click="dialogVisible=false">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import uploadImg from '@/components/common/upload.vue'
import LUpload from "@/views/student-recruitment/client-server/supplement-to-the-enrollment-of-new-students/l-upload.vue";

export default {
  _config: {"route": {"path": "add", "meta": {"title": "新增"}}},
  components: {LUpload, uploadImg},
  data() {
    return {
      dynamicText: '高一学年应缴金额',
      formArr: [
        {
          label: '报名校区',
          value: 'school_id',
          placeholder: '请选择报名校区',
          options: [],
          config: 'school_data',
          label_: 'school_name',
          value_: 'school_id',
          change: (val) => {
            this.$_register('api/recruit-v2/common/goods-standard?school_id=' + val).then(res => {
              this.formArr[5].options = res.data.data
              this.form.goods_standard_id = this.formArr[5].options.find(standard => standard.standard_name === '6人间')?.goods_standard_id || ''
            })
          },
        },
        {
          label: '学生类型',
          value: 'student_type',
          placeholder: '请选择学生类型',
          options: [],
          config: 'student_type_add',
          label_: 'student_type_name',
          value_: 'student_type',
        },
        {
          label: '入学年份',
          value: 'grade_year',
          placeholder: '请选择入学年份',
          options: [],
          config: 'grade_year_add',
        },
        {
          label: '所属业务线',
          value: 'business_line',
          placeholder: '请选择所属业务线',
          options: [],
          config: 'business_line_add',
          label_: 'business_line_name',
          value_: 'business_line',
        },
        {
          label: '入学年级',
          value: 'grade_id',
          placeholder: '请选择入学年级',
          options: [],
          config: 'grade_add',
          label_: 'grade_name',
          value_: 'grade_id',
        },
        {
          label: '住宿寝室',
          value: 'goods_standard_id',
          placeholder: '请选择住宿寝室',
          options: [],
          label_: 'standard_name',
          value_: 'goods_standard_id',
        },
        {
          label: '学生姓名',
          value: 'student_name',
          placeholder: '请输入学生姓名'
        },
        {
          label: '学生身份证号',
          value: 'student_idcard',
          placeholder: '请输入学生身份证号'
        },
        {
          label: '',
          value: '',
        },
        {
          label: '缴费方式',
          value: 'payment_type',
          placeholder: '请选择缴费方式',
          options: [],
          config: 'payment_type_add',
          label_: 'payment_type_name',
          value_: 'payment_type',
          change: (val) => {
            this.formArr[12].show = val===1
            this.formArr[16].show = val===1
            this.dynamicText = `高一学年${val===1 ? '应' : '实'}缴金额`
            this.formArr[11].label = this.dynamicText
            this.formArr[11].placeholder = `请输入${this.dynamicText}`
          }
        },
        {
          label: '是否缴纳高一学年生活费',
          value: 'living_pay',
          placeholder: '请选择是否缴纳高一学年生活费',
          options: [],
          config: 'living_pay_add',
          label_: 'living_pay_name',
          value_: 'living_pay',
        },
        {
          label: '高一学年应缴金额',
          value: 'payment_amount',
          placeholder: '请输入高一学年应缴金额'
        },
        {
          label: '缴费时间',
          value: 'pay_time',
          placeholder: '请选择缴费时间',
          show: true
        },
        {
          label: '所参与优惠政策',
          value: 'policy',
          placeholder: '',
          config: 'policy_add',
          options: []
        },
        {
          label: '其他优惠原因',
          value: 'other_reasons',
          placeholder: '请输入其他优惠原因',
          show: true
        },
        {
          label: '【特殊减免】优惠原因',
          value: 'reduction_reasons',
          placeholder: '请输入特殊减免原因',
          show: true
        },
        {
          label: '缴费凭证',
          value: 'payment_voucher',
          placeholder: '请输入',
          options: [],
          config: 'grade_year_add',
          label_: 'school_name',
          value_: 'school_id',
          show: true
        },
        {
          label: '高二学年应缴金额',
          value: 'two_payment_amount',
          placeholder: '请输入高二学年应缴金额'
        },
        {
          label: '高三学应缴金额',
          value: 'three_payment_amount',
          placeholder: '请输入高三学年应缴金额'
        },
      ],
      form: {
        school_id: '',
        grade_id: '',
        grade_year: String(new Date().getFullYear()),
        student_type: '',
        business_line: '',
        goods_standard_id: '',
        student_name: '',
        student_idcard: '',
        payment_type: '',
        living_pay: '',
        payment_amount: '',
        pay_time: '',
        policy: [],
        other_reasons: '',
        payment_voucher: [],
        two_payment_amount: '',
        three_payment_amount: '',
        reduction_reasons: '',
        remarks: ''
      },
      rules: {
        school_id: [
          {required: true, message: '请选择报名校区', trigger: 'change'},
        ],
        grade_id: [
          {required: true, message: '请选择入学年级', trigger: 'change'},
        ],
        grade_year: [
          {required: true, message: '请选择入学年份', trigger: 'change'},
        ],
        student_type: [
          {required: true, message: '请选择学生类型', trigger: 'change'},
        ],
        business_line: [
          {required: true, message: '请选择所属业务线', trigger: 'change'},
        ],
        goods_standard_id: [
          {required: true, message: '请选择住宿寝室', trigger: 'change'},
        ],
        student_name: [
          {required: true, message: '请输入学生姓名', trigger: 'change'},
        ],
        student_idcard: [
          {required: true, message: '请输入学生身份证号', trigger: 'change'},
          {pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的身份证号', trigger: 'change'},
        ],
        contract_status: [
          {required: true, message: '请选择是否进行签约', trigger: 'change'},
        ],
        payment_type: [
          {required: true, message: '请选择缴费方式', trigger: 'change'},
        ],
        living_pay: [
          {required: true, message: '请选择是否缴纳高一学年生活费', trigger: 'change'},
        ],
        payment_amount: [
          {required: true, message: '请输入实缴金额', trigger: 'change'},
        ],
        pay_time: [
          {required: true, message: '请选择缴费时间', trigger: 'change'},
        ],
        policy: [
          {required: true, message: '请选择所参与优惠政策', trigger: 'change'},
        ],
        other_reasons: [
          {required: true, message: '请输入其他优惠原因', trigger: 'change'},
        ],
        reduction_reasons: [
          {required: true, message: '请输入优惠原因', trigger: 'change'},
        ],
        two_payment_amount: [
          {required: true, message: '请输入高二学年应缴金额', trigger: 'change'},
        ],
        three_payment_amount: [
          {required: true, message: '请输入高三学年应缴金额', trigger: 'change'},
        ]
      },
      dialogVisible: false,
      errMsg: ''
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate((value) => {
        if (value) {
          this.$_register.get('api/recruit-v2/offline-fresh/formal-order', {
            logic: 1,
            params: {student_idcard: this.form.student_idcard}
          }).then(() => {
            this.errMsg = ''
            this.dialogVisible = true
          }).catch(err => {
            this.errMsg = err.data.msg
            this.dialogVisible = true
          })
        }
      })
    },
    onConfirmed() {
      let form = {...this.form};
      if (!this.form.policy.includes(5)) {
        form.reduction_reasons = '';
      }
      if (!this.form.policy.includes(7)) {
        form.other_reasons = '';
      }
      this.$_register.post('api/recruit-v2/offline-fresh/add', form, {logic: 1}).then(() => {
        this.$router.back()
        this.$message.success('新增成功')
      }).catch(err => {
        this.$message.error(err.data.msg)
        if (err.data.status === 500) {
          this.errMsg = err.data.msg
          this.dialogVisible = true
        }
      });
    },
    onPolicy(v, n) {
      if (this.form.policy.includes(v.policy)) {
        this.form.policy.splice(this.form.policy.indexOf(v.policy), 1)
        this.formArr[n + 2].show = !this.form.policy.includes(5);
        this.formArr[n + 1].show = !this.form.policy.includes(7);
        return
      }
      if (v.policy === 5 || v.policy === 6) {
        this.form.policy = [v.policy]
      }
      if (v.policy !== 5 || v.policy !== 6) {
        this.form.policy = this.form.policy.filter(policy => policy !== 5 && policy !== 6);
        this.form.policy.push(v.policy)
      }
      this.formArr[n + 2].show = !this.form.policy.includes(5);
      this.formArr[n + 1].show = !this.form.policy.includes(7);
    },

    imgUpload(v) {
      this.form.payment_voucher.push(v)
    },
    imgUploadRemove(v) {
      this.form.payment_voucher = this.form.payment_voucher.filter(policy => policy !== v);
    },
  },
  created() {
    this.$_register.get('api/recruit-v2/offline-fresh/config').then(res => {
      this.formArr.forEach(item => {
        if (item.config) {
          item.options = res.data.data[item.config]
        }
      })
    })
  },
}
</script>
<style lang="scss" scoped>
.main {
  background: #F7F7F7 !important;
  box-sizing: border-box;
  min-width: 1440rem;
  padding-bottom: 24rem;
  padding-right: 24rem;

  .registration-information {
    background: #FFFFFF;
    border-radius: 8rem;
    min-height: 120rem;
    width: 100%;

    .text {
      border-bottom: 1px solid #F2F2F2;
      color: #222222;
      font-size: 20rem;
      font-weight: 500;
      height: 52rem;
      line-height: 52rem;
      text-indent: 24rem;
    }

    .form {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 24rem 48rem;
    }
  }
}

::v-deep.label-top .el-form-item__label {
  display: block;
  float: none;
}

::v-deep.label-top .el-form-item__error {
  margin-left: -120rem;
}

.textarea {
  margin-left: -120rem;
}

::v-deep.form-item:nth-of-type(2) .el-form-item__label {
  margin-left: -40rem;
  width: 180rem !important;
}

::v-deep.form-item1:nth-of-type(1) .el-form-item__label {
  margin-left: -40rem;
  width: 180rem !important;
}

::v-deep.form-item .el-upload {
  display: flex;
}

::v-deep .el-upload--picture-card {
  display: none !important;
}
</style>
